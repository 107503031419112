var render = function () {
  var _vm$meta$catatan_kegi, _vm$meta, _vm$dateHumanReadAble, _vm$meta$wkt_keg_renc, _vm$meta2, _vm$dateHumanReadAble2, _vm$meta$updated_at, _vm$meta3, _vm$form$wkt_durasi_k, _vm$form$wkt_produkti;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "yogi-ptsal"
  }, [_vm.isLoading ? _c('b-progress', {
    key: "info",
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": "100",
      "variant": "info"
    }
  }) : _vm._e(), _vm.isLoading ? _c('div', [_c('hr'), _vm._v(" . . . Loading . . . "), _c('hr')]) : _vm._e(), _c('div', [_c('h1', [_c('feather-icon', {
    attrs: {
      "size": "30",
      "icon": 'PrinterIcon'
    }
  }), _vm._v(" " + _vm._s(!!_vm.dataID ? 'EDIT' : 'BUAT') + " REALISASI LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) "), _c('feather-icon', {
    attrs: {
      "size": "30",
      "icon": 'PrinterIcon'
    }
  })], 1), _c('hr'), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 text-danger",
    attrs: {
      "title": "Catatan Kegiatan",
      "size": "25",
      "icon": 'MessageSquareIcon'
    }
  }), _c('b-badge', {
    attrs: {
      "variant": 'light-danger'
    }
  }, [_vm._v(" " + _vm._s((_vm$meta$catatan_kegi = (_vm$meta = _vm.meta) === null || _vm$meta === void 0 ? void 0 : _vm$meta.catatan_kegiatan) !== null && _vm$meta$catatan_kegi !== void 0 ? _vm$meta$catatan_kegi : '-') + " ")]), _c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 ml-1 text-success",
    attrs: {
      "title": "Rencana tanggal Berkegiatan",
      "size": "25",
      "icon": 'ClockIcon'
    }
  }), _c('b-badge', {
    attrs: {
      "variant": 'light-success'
    }
  }, [_vm._v(" " + _vm._s((_vm$dateHumanReadAble = _vm.dateHumanReadAble((_vm$meta$wkt_keg_renc = (_vm$meta2 = _vm.meta) === null || _vm$meta2 === void 0 ? void 0 : _vm$meta2.wkt_keg_rencana) !== null && _vm$meta$wkt_keg_renc !== void 0 ? _vm$meta$wkt_keg_renc : '')) !== null && _vm$dateHumanReadAble !== void 0 ? _vm$dateHumanReadAble : '') + " ")]), _c('feather-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-1 ml-1 text-warning",
    attrs: {
      "title": "Terakhir rencana berkegiatan di Update",
      "size": "25",
      "icon": 'ClockIcon'
    }
  }), _c('b-badge', {
    attrs: {
      "variant": 'light-warning'
    }
  }, [_vm._v(" " + _vm._s((_vm$dateHumanReadAble2 = _vm.dateHumanReadAble((_vm$meta$updated_at = (_vm$meta3 = _vm.meta) === null || _vm$meta3 === void 0 ? void 0 : _vm$meta3.updated_at) !== null && _vm$meta$updated_at !== void 0 ? _vm$meta$updated_at : '')) !== null && _vm$dateHumanReadAble2 !== void 0 ? _vm$dateHumanReadAble2 : '') + " ")])], 1), _c('hr')]), _c('form-wizard', {
    key: "wizardKey",
    ref: "wizardFirst",
    staticClass: "mb-3",
    attrs: {
      "color": "#7367F0",
      "title": null,
      "subtitle": null,
      "lazy": true,
      "shape": "square",
      "finish-button-text": "Submit ( LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) )",
      "back-button-text": "Previous"
    },
    on: {
      "on-complete": _vm.formSubmitted
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "wizard-footer-left"
        }, [props.activeTabIndex > 0 && !props.isLastStep ? _c('wizard-button', {
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return props.prevTab();
            }
          }
        }, [_vm._v("Previous")]) : _vm._e()], 1), _c('div', {
          staticClass: "wizard-footer-right"
        }, [!props.isLastStep ? _c('wizard-button', {
          staticClass: "wizard-footer-right",
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return props.nextTab();
            }
          }
        }, [_vm._v("Next")]) : _c('wizard-button', {
          staticClass: "wizard-footer-right finish-button",
          style: props.fillButtonStyle,
          attrs: {
            "disabled": _vm.isLoading
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.formSubmitted.apply(null, arguments);
            }
          }
        }, [_vm.isLoading ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _c('strong', [_vm._v(" " + _vm._s(props.isLastStep ? !!_vm.dataID ? 'Simpan ( LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) )' : 'Submit ( LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) )' : 'Next') + " ")])], 1)], 1)];
      }
    }])
  }, [_c('tab-content', {
    attrs: {
      "title": "Kegiatan",
      "before-change": _vm.validationPhase1
    }
  }, [_c('validation-observer', {
    ref: "phase1Rule",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Kegiatan")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Enter Your Kegiatan.")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Kegiatan",
      "label-for": "vi-jenis_kegiatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jenis Kegiatan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-jenis_kegiatan",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.options.jenis_kegiatans,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "label": "text",
            "item-text": "text",
            "item-value": "value"
          },
          model: {
            value: _vm.form.jns_kegiatan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "jns_kegiatan", $$v);
            },
            expression: "form.jns_kegiatan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nama Barang",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Nama Barang",
            "label-for": "nama_barang",
            "state": errors.length > 0 ? false : null
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nama_barang",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Nama Barang"
          },
          model: {
            value: _vm.form.nama_barang,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nama_barang", $$v);
            },
            expression: "form.nama_barang"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Satuan ( Automatic UPPERCASE )",
      "label-for": "satuan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "satuan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "name",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Contoh: TON"
          },
          model: {
            value: _vm.form.satuan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "satuan", $$v);
            },
            expression: "form.satuan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Volume",
      "label-for": "volume"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "volume",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": _vm.form.satuan
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "volume",
            "raw": true,
            "options": _vm.options.number,
            "placeholder": "Contoh 10.000 (dot automaticaly )"
          },
          model: {
            value: _vm.form.volume,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "volume", $$v);
            },
            expression: "form.volume"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Lokasi Kegiatan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Lokasi Kegiatan",
            "label-for": "lokasi_keg",
            "state": errors.length > 0 ? false : null
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "lokasi_keg",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Lokasi Kegiatan"
          },
          model: {
            value: _vm.form.lokasi_keg,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "lokasi_keg", $$v);
            },
            expression: "form.lokasi_keg"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Asal Barang",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Asal Barang",
            "label-for": "asal_barang",
            "state": errors.length > 0 ? false : null
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "asal_barang",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Asal Barang"
          },
          model: {
            value: _vm.form.asal_barang,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "asal_barang", $$v);
            },
            expression: "form.asal_barang"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tujuan Barang",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Tujuan Barang",
            "label-for": "tujuan_barang",
            "state": errors.length > 0 ? false : null
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "tujuan_barang",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Tujuan Barang"
          },
          model: {
            value: _vm.form.tujuan_barang,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tujuan_barang", $$v);
            },
            expression: "form.tujuan_barang"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Pemilik Barang",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Pemilik Barang",
            "label-for": "pemilik_barang",
            "state": errors.length > 0 ? false : null
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "pemilik_barang",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Pemilik Barang"
          },
          model: {
            value: _vm.form.pemilik_barang,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "pemilik_barang", $$v);
            },
            expression: "form.pemilik_barang"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "TKBM",
      "label-for": "tkbm"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "TKBM",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "tkbm",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": "Contoh: 5"
          },
          model: {
            value: _vm.form.tkbm,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tkbm", $$v);
            },
            expression: "form.tkbm"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor Kendaraan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Nomor Kendaraan",
            "label-for": "no_kendaraan",
            "state": errors.length > 0 ? false : null
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "no_kendaraan",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Nomor Kendaraan"
          },
          model: {
            value: _vm.form.no_kendaraan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "no_kendaraan", $$v);
            },
            expression: "form.no_kendaraan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Peralatan",
      "before-change": _vm.validationPhase3
    }
  }, [_c('validation-observer', {
    ref: "phase3Rule",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Peralatan")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Enter Your Peralatan.")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', _vm._l(_vm.itemPeralatans, function (item, index) {
    return _c('b-col', {
      key: item.fe_id,
      ref: "rowPeralatan",
      refInFor: true,
      staticClass: "repeater-form",
      attrs: {
        "cols": "6",
        "id": item.fe_id
      }
    }, [_c('b-card', [_c('b-row', [_c('b-col', {
      attrs: {
        "cols": "1"
      }
    }, [_c('b-badge', {
      staticClass: "mt-0",
      attrs: {
        "pill": "",
        "variant": "info"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")])], 1), _c('b-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('hr')]), _c('b-col', {
      attrs: {
        "cols": "1"
      }
    }, [_c('b-badge', {
      attrs: {
        "pill": "",
        "variant": "danger"
      }
    }, [_c('feather-icon', {
      style: {
        cursor: 'pointer'
      },
      attrs: {
        "icon": "XIcon",
        "size": "25"
      },
      on: {
        "click": function click($event) {
          return _vm.removeItemPeralatan(index);
        }
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Peralatan",
        "label-for": "vi-peralatan-operator"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Peralatan",
        "vid": "vi-peralatan-operator",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref11) {
          var errors = _ref11.errors;
          return [_c('v-select', {
            attrs: {
              "id": "vi-peralatan-operator",
              "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
              "reduce": function reduce(option) {
                return option.value;
              },
              "label": "label",
              "options": _vm.options.peralatans
            },
            model: {
              value: item.peralatan,
              callback: function callback($$v) {
                _vm.$set(item, "peralatan", $$v);
              },
              expression: "item.peralatan"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Operator / Driver",
        "label-for": "vi-operator-driver"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Operator / Driver",
        "vid": "vi-operator-driver",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref12) {
          var errors = _ref12.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": "operator-driver",
              "state": errors.length > 0 ? false : null,
              "placeholder": "Nama Operator / Driver"
            },
            model: {
              value: item.operator_driver,
              callback: function callback($$v) {
                _vm.$set(item, "operator_driver", $$v);
              },
              expression: "item.operator_driver"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1)], 1)], 1)], 1);
  }), 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "md",
      "variant": "outline-primary",
      "block": ""
    },
    on: {
      "click": _vm.repeateAgainPeralatan
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _c('span', [_vm._v("Tambah Peralatan")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('tab-content', {
    attrs: {
      "title": "Realisasi Tanggal Berkegiatan",
      "before-change": _vm.validationPhase2
    }
  }, [_c('validation-observer', {
    ref: "phase2Rule",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Realisasi Tanggal Berkegiatan")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Enter Your Realisasi Tanggal Berkegiatan.")])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Berkegiatan",
      "label-for": "waktu-mulai-berkegiatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Berkegiatan"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configs.end
          },
          on: {
            "on-change": _vm.onEndChange
          },
          model: {
            value: _vm.form.wkt_keg_mulai,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "wkt_keg_mulai", $$v);
            },
            expression: "form.wkt_keg_mulai"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Berkegiatan"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Waktu Selesai Berkegiatan",
            "label-for": "waktu-selesai-berkegiatan",
            "state": errors.length > 0 ? false : null
          }
        }, [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configs.end
          },
          on: {
            "on-change": _vm.onEndChange
          },
          model: {
            value: _vm.form.wkt_keg_selesai,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "wkt_keg_selesai", $$v);
            },
            expression: "form.wkt_keg_selesai"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor Surat Jalan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Nomor Surat Jalan",
            "label-for": "nomor-surat-jalan",
            "state": errors.length > 0 ? false : null
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nomor-surat-jalan",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Nomor Surat Jalan"
          },
          model: {
            value: _vm.form.no_surat_jln,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "no_surat_jln", $$v);
            },
            expression: "form.no_surat_jln"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "mt-1"
  }, [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": 'ClockIcon'
    }
  }), _vm._v(" Durasi :")], 1), _c('b-badge', {
    staticClass: "ml-2",
    attrs: {
      "pill": "",
      "variant": 'primary'
    }
  }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s((_vm$form$wkt_durasi_k = _vm.form.wkt_durasi_keg) !== null && _vm$form$wkt_durasi_k !== void 0 ? _vm$form$wkt_durasi_k : '-') + " ")])])]), _c('br'), _c('i', [_c('strong', [_vm._v("[ Rentang waktu mulai berkegiatan sampai waktu selesai berkegiatan ]")])]), _c('hr'), _c('strong', [_c('feather-icon', {
    attrs: {
      "icon": 'BarChartIcon'
    }
  }), _vm._v(" Produktivitas / Jam :")], 1), _c('b-badge', {
    staticClass: "ml-2",
    attrs: {
      "pill": "",
      "variant": 'primary'
    }
  }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s((_vm$form$wkt_produkti = _vm.form.wkt_produktivitas) !== null && _vm$form$wkt_produkti !== void 0 ? _vm$form$wkt_produkti : '-') + " ")])])]), _c('br'), _c('strong', [_c('i', [_vm._v(" [ Volume Produktivitas dalam Per Jam ] ")])])], 1)], 1)], 1)], 1)], 1), _c('strong', [_c('feather-icon', {
    attrs: {
      "icon": 'BarChartIcon'
    }
  })], 1), _c('i', [_c('strong', [_vm._v(" [ " + _vm._s(_vm.$t('quotes-jb-realisasi')) + " ] ")])]), _c('hr'), _c('strong', [_c('feather-icon', {
    attrs: {
      "icon": 'AwardIcon'
    }
  })], 1), _c('strong', [_c('i', [_vm._v(" [ " + _vm._s(_vm.$t('quotes-jb-realisasi1')) + " ] ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }