<template>
  <div class="yogi-ptsal">
    <b-progress
      v-if="isLoading"
      key="info"
      animated
      value="100"
      variant="info"
      :class="'progress-bar-info'"
    />
    <div v-if="isLoading">
      <hr />
      . . . Loading . . .
      <hr />
    </div>
    <div>
      <h1>
        <feather-icon size="30" :icon="'PrinterIcon'" />
        {{ !!dataID ? 'EDIT' : 'BUAT' }} REALISASI LAPRORAN KEGIATAN OPERASIONAL
        ( Jasa Barang )
        <feather-icon size="30" :icon="'PrinterIcon'" />
      </h1>
      <hr />
      <div class="d-flex align-items-center">
        <feather-icon
          v-b-tooltip.hover
          title="Catatan Kegiatan"
          size="25"
          :icon="'MessageSquareIcon'"
          class="mr-1 text-danger"
        />
        <b-badge :variant="'light-danger'">
          {{ meta?.catatan_kegiatan ?? '-' }}
        </b-badge>

        <feather-icon
          v-b-tooltip.hover
          title="Rencana tanggal Berkegiatan"
          size="25"
          :icon="'ClockIcon'"
          class="mr-1 ml-1 text-success"
        />
        <b-badge :variant="'light-success'">
          {{ dateHumanReadAble(meta?.wkt_keg_rencana ?? '') ?? '' }}
        </b-badge>

        <feather-icon
          v-b-tooltip.hover
          title="Terakhir rencana berkegiatan di Update"
          size="25"
          :icon="'ClockIcon'"
          class="mr-1 ml-1 text-warning"
        />
        <b-badge :variant="'light-warning'">
          {{ dateHumanReadAble(meta?.updated_at ?? '') ?? '' }}
        </b-badge>
      </div>
      <hr />
    </div>

    <form-wizard
      key="wizardKey"
      ref="wizardFirst"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      :lazy="true"
      shape="square"
      finish-button-text="Submit ( LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) )"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <tab-content title="Kegiatan" :before-change="validationPhase1">
        <validation-observer ref="phase1Rule" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Kegiatan</h5>
              <small class="text-muted">Enter Your Kegiatan.</small>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Jenis Kegiatan"
                label-for="vi-jenis_kegiatan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jenis Kegiatan"
                  rules="required"
                >
                  <v-select
                    id="vi-jenis_kegiatan"
                    v-model="form.jns_kegiatan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="options.jenis_kegiatans"
                    :reduce="(option) => option.value"
                    :selectable="(option) => !option.value.includes('null')"
                    label="text"
                    item-text="text"
                    item-value="value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" cols="12">
              <validation-provider
                #default="{ errors }"
                name="Nama Barang"
                rules="required"
              >
                <b-form-group
                  label="Nama Barang"
                  label-for="nama_barang"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="nama_barang"
                    v-model="form.nama_barang"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nama Barang"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4" cols="12">
              <b-form-group
                label="Satuan ( Automatic UPPERCASE )"
                label-for="satuan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="satuan"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-uppercase
                    v-model="form.satuan"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Contoh: TON"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" cols="12">
              <b-form-group label="Volume" label-for="volume">
                <validation-provider
                  #default="{ errors }"
                  name="volume"
                  rules="required"
                >
                  <b-input-group :append="form.satuan">
                    <cleave
                      id="volume"
                      v-model="form.volume"
                      class="form-control"
                      :raw="true"
                      :options="options.number"
                      placeholder="Contoh 10.000 (dot automaticaly )"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" cols="12">
              <validation-provider
                #default="{ errors }"
                name="Lokasi Kegiatan"
                rules="required"
              >
                <b-form-group
                  label="Lokasi Kegiatan"
                  label-for="lokasi_keg"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="lokasi_keg"
                    v-model="form.lokasi_keg"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Lokasi Kegiatan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4" cols="12">
              <validation-provider
                #default="{ errors }"
                name="Asal Barang"
                rules="required"
              >
                <b-form-group
                  label="Asal Barang"
                  label-for="asal_barang"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="asal_barang"
                    v-model="form.asal_barang"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Asal Barang"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4" cols="12">
              <validation-provider
                #default="{ errors }"
                name="Tujuan Barang"
                rules="required"
              >
                <b-form-group
                  label="Tujuan Barang"
                  label-for="tujuan_barang"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="tujuan_barang"
                    v-model="form.tujuan_barang"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Tujuan Barang"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4" cols="12">
              <validation-provider
                #default="{ errors }"
                name="Pemilik Barang"
                rules="required"
              >
                <b-form-group
                  label="Pemilik Barang"
                  label-for="pemilik_barang"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="pemilik_barang"
                    v-model="form.pemilik_barang"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Pemilik Barang"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4" cols="12">
              <b-form-group label="TKBM" label-for="tkbm">
                <validation-provider
                  #default="{ errors }"
                  name="TKBM"
                  rules="required"
                >
                  <b-form-input
                    id="tkbm"
                    v-model="form.tkbm"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    placeholder="Contoh: 5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" cols="12">
              <validation-provider
                #default="{ errors }"
                name="Nomor Kendaraan"
                rules="required"
              >
                <b-form-group
                  label="Nomor Kendaraan"
                  label-for="no_kendaraan"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="no_kendaraan"
                    v-model="form.no_kendaraan"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nomor Kendaraan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content title="Peralatan" :before-change="validationPhase3">
        <validation-observer ref="phase3Rule" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Peralatan</h5>
              <small class="text-muted">Enter Your Peralatan.</small>
            </b-col>

            <b-col cols="12">
              <b-row>
                <b-col
                  cols="6"
                  v-for="(item, index) in itemPeralatans"
                  :id="item.fe_id"
                  :key="item.fe_id"
                  ref="rowPeralatan"
                  class="repeater-form"
                >
                  <b-card>
                    <b-row>
                      <b-col cols="1">
                        <b-badge pill variant="info" class="mt-0">
                          {{ index + 1 }}
                        </b-badge>
                      </b-col>
                      <b-col cols="10">
                        <hr />
                      </b-col>
                      <b-col cols="1">
                        <b-badge pill variant="danger">
                          <feather-icon
                            icon="XIcon"
                            size="25"
                            :style="{ cursor: 'pointer' }"
                            @click="removeItemPeralatan(index)"
                          />
                        </b-badge>
                      </b-col>

                      <b-col lg="6" md="6" cols="12">
                        <b-form-group
                          label="Peralatan"
                          label-for="vi-peralatan-operator"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Peralatan"
                            vid="vi-peralatan-operator"
                            rules="required"
                          >
                            <v-select
                              id="vi-peralatan-operator"
                              v-model="item.peralatan"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :reduce="(option) => option.value"
                              label="label"
                              :options="options.peralatans"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col lg="6" md="6" cols="12">
                        <b-form-group
                          label="Operator / Driver"
                          label-for="vi-operator-driver"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Operator / Driver"
                            vid="vi-operator-driver"
                            rules="required"
                          >
                            <b-form-input
                              id="operator-driver"
                              v-model="item.operator_driver"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Nama Operator / Driver"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- END OF LOOPING FIELD -->
              </b-row>
              <b-row>
                <b-col lg="12" md="12" cols="12">
                  <b-button
                    size="md"
                    variant="outline-primary"
                    block
                    @click="repeateAgainPeralatan"
                  >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Tambah Peralatan</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content
        title="Realisasi Tanggal Berkegiatan"
        :before-change="validationPhase2"
      >
        <validation-observer ref="phase2Rule" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Realisasi Tanggal Berkegiatan</h5>
              <small class="text-muted"
                >Enter Your Realisasi Tanggal Berkegiatan.</small
              >
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Waktu Mulai Berkegiatan"
                label-for="waktu-mulai-berkegiatan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Waktu Mulai Berkegiatan"
                >
                  <flat-pickr
                    v-model="form.wkt_keg_mulai"
                    class="form-control"
                    static="true"
                    :state="errors.length > 0 ? false : null"
                    :config="configs.end"
                    @on-change="onEndChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Waktu Selesai Berkegiatan"
              >
                <b-form-group
                  label="Waktu Selesai Berkegiatan"
                  label-for="waktu-selesai-berkegiatan"
                  :state="errors.length > 0 ? false : null"
                >
                  <flat-pickr
                    v-model="form.wkt_keg_selesai"
                    class="form-control"
                    static="true"
                    :state="errors.length > 0 ? false : null"
                    :config="configs.end"
                    @on-change="onEndChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Nomor Surat Jalan"
                rules="required"
              >
                <b-form-group
                  label="Nomor Surat Jalan"
                  label-for="nomor-surat-jalan"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="nomor-surat-jalan"
                    v-model="form.no_surat_jln"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nomor Surat Jalan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="mt-1">
              <strong><feather-icon :icon="'ClockIcon'" /> Durasi :</strong>
              <b-badge pill :variant="'primary'" class="ml-2">
                <strong
                  ><span> {{ form.wkt_durasi_keg ?? '-' }} </span></strong
                >
              </b-badge>
              <br /><i
                ><strong
                  >[ Rentang waktu mulai berkegiatan sampai waktu selesai
                  berkegiatan ]</strong
                ></i
              >
              <hr />

              <strong
                ><feather-icon :icon="'BarChartIcon'" /> Produktivitas / Jam
                :</strong
              >
              <b-badge pill :variant="'primary'" class="ml-2">
                <strong
                  ><span> {{ form.wkt_produktivitas ?? '-' }} </span></strong
                >
              </b-badge>
              <br /><strong
                ><i> [ Volume Produktivitas dalam Per Jam ] </i></strong
              >
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <template slot-scope="props" slot="footer">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0 && !props.isLastStep"
            @click.native="props.prevTab()"
            :style="props.fillButtonStyle"
            >Previous</wizard-button
          >
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="!props.isLastStep"
            @click.native="props.nextTab()"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            >Next</wizard-button
          >

          <wizard-button
            v-else
            @click.native="formSubmitted"
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" small></b-spinner>
            <feather-icon v-else icon="CalendarIcon" class="mr-50" />
            <strong>
              {{
                props.isLastStep
                  ? !!dataID
                    ? 'Simpan ( LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) )'
                    : 'Submit ( LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) )'
                  : 'Next'
              }}
            </strong>
          </wizard-button>
        </div>
      </template>
    </form-wizard>

    <strong><feather-icon :icon="'BarChartIcon'" /> </strong>
    <i>
      <strong> [ {{ $t('quotes-jb-realisasi') }} ] </strong>
    </i>
    <hr />

    <strong><feather-icon :icon="'AwardIcon'" /></strong>
    <strong
      ><i> [ {{ $t('quotes-jb-realisasi1') }} ] </i></strong
    >
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCardText,
  BAvatar,
  BBadge
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BAvatar,
    BBadge,
    Indonesian,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    WizardButton,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCardActions,
    BCardText,
    Cleave
  },
  data() {
    return {
      onceRender: false,
      jenis_produk: 0,
      dataID: this.$router.currentRoute.params.id,
      meta: this.$router.currentRoute.params.meta,
      isExistDetailID: !!this.$router.currentRoute.params.id /* Boolean */,
      metode_pelayanan: 0,
      isLoading: false,
      form: {
        opr_form_kegiatan_laporan_id:
          this.$router.currentRoute.params.opr_form_kegiatan_laporan_id,
        jns_kegiatan: null,
        wkt_keg_mulai: null,
        wkt_keg_selesai: null,
        wkt_durasi_keg: null,
        nama_barang: null,
        volume: null,
        satuan: null,
        lokasi_keg: null,
        asal_barang: null,
        tujuan_barang: null,
        pemilik_barang: null,
        no_surat_jln: null,
        wkt_produktivitas: null,
        tkbm: null,
        no_kendaraan: null
      },
      itemPeralatans: [
        {
          fe_id: 1
          // no_document: '',
          // asal_brg: '',
          // jenis_brg: '',
          // jml_muatan: 0,
          // satuan: 0,
        }
      ],
      nextItemPeralatanID: 2,
      options: {
        jenis_kegiatans: [
          { text: 'Cargodoring', value: 'Cargodoring' },
          { text: 'Stevedoring', value: 'Stevedoring' },
          { text: 'Receiving', value: 'Receiving' },
          { text: 'Delivering', value: 'Delivering' },
          { text: 'Internal Base Transfer', value: 'Internal Base Transfer' }
        ],
        peralatans: [
          {
            label: 'CCS 02',
            value: 'CCS 02'
          },
          {
            label: 'CCS 03',
            value: 'CCS 03'
          },
          {
            label: 'FK 01',
            value: 'FK 01'
          },
          {
            label: 'FK 02',
            value: 'FK 02'
          },
          {
            label: 'FK 03',
            value: 'FK 03'
          },
          {
            label: 'FK 05',
            value: 'FK 05'
          },
          {
            label: 'FK 06',
            value: 'FK 06'
          },
          {
            label: 'FK 07',
            value: 'FK 07'
          },
          {
            label: 'FK 08',
            value: 'FK 08'
          },
          {
            label: 'FK 09',
            value: 'FK 09'
          },
          {
            label: 'FK 10',
            value: 'FK 10'
          }
        ],
        number: {
          // numeral: true,
          // numeralThousandsGroupStyle: 'thousand',
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
          // suffix: 'LTR',
        }
      },
      dpconfig: {
        // default
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      dpconfigRevisi: {
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      configs: {
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altInput: true,
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      }
    }
  },
  watch: {
    // '$route.params': function (params) {
    //   this.dataID = params.id
    //   this.isExistDetailID = !!params.id
    //   this.fetchData(true)
    // },
    form: function () {
      console.log('form live', this.form)
    },
    'form.wkt_keg_mulai': function () {
      const { wkt_keg_mulai, wkt_keg_selesai } = this.form
      if (wkt_keg_selesai) {
        const duration = this.durationAsString(wkt_keg_mulai, wkt_keg_selesai)
        this.form.wkt_durasi_keg = duration
      }
    },
    'form.wkt_keg_selesai': function () {
      const { wkt_keg_mulai, wkt_keg_selesai } = this.form
      if (wkt_keg_mulai) {
        const duration = this.durationAsString(wkt_keg_mulai, wkt_keg_selesai)
        this.form.wkt_durasi_keg = duration
      }
    },
    'form.volume': function () {
      const { wkt_keg_mulai, wkt_keg_selesai, volume } = this.form
      if (!!wkt_keg_mulai && !!wkt_keg_selesai) {
        const duration = this.durationAsString(wkt_keg_mulai, wkt_keg_selesai)
        this.form.wkt_durasi_keg = duration
      }
    }
  },
  computed: {
    // selectedJenisProdukModel: {
    //   get() {
    //     if (this.jenis_produk === 0) {
    //       return this.form.jns_prdk_curah_cair
    //     } else if (this.jenis_produk === 1) {
    //       return this.form.jns_prdk_curah_kering
    //     } else if (this.jenis_produk === 2) {
    //       return this.form.jns_prdk_unitized
    //     } else {
    //       // Default case, you can handle it accordingly
    //       return null
    //     }
    //   },
    //   set(value) {
    //     if (this.jenis_produk === 0) {
    //       this.form.jns_prdk_curah_cair = value
    //     } else if (this.jenis_produk === 1) {
    //       this.form.jns_prdk_curah_kering = value
    //     } else if (this.jenis_produk === 2) {
    //       this.form.jns_prdk_unitized = value
    //     }
    //   }
    // },
    // selectJenisProduk() {
    //   let string = ''
    //   if (this.jenis_produk == 0) string = 'Curah Cair'
    //   if (this.jenis_produk == 1) string = 'Curah Kering'
    //   if (this.jenis_produk == 2) string = 'Unitized'
    //   if (!this.dataID) {
    //     this.form = {
    //       ...this.form,
    //       jns_prdk_curah_cair: null,
    //       jns_prdk_curah_kering: null,
    //       jns_prdk_unitized: null
    //     }
    //   }
    //   return (any) => string
    // }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    moment,
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - HH:mm')
      if (format === 'Invalid date') {
        return '-'
      }
      return format
    },
    repeateAgainPeralatan() {
      this.itemPeralatans.push({
        fe_id: (this.nextItemPeralatanID += this.nextItemPeralatanID)
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rowPeralatan[0].offsetHeight)
      })
    },
    removeItemPeralatan(index) {
      this.itemPeralatans.splice(index, 1)
      this.trTrimHeight(this.$refs.rowPeralatan[0].offsetHeight)
    },
    async fetchData() {
      const uriAPI = router.currentRoute.params.api
      const dataID = this.dataID

      if (dataID !== undefined) {
        this.isLoading = true

        await useJwt.http.get(`${uriAPI}/${dataID}`).then((response) => {
          const { opr_form_kegiatan_laporan_adms } = response.data
          const { opr_keg_mb_lp_peralatans } = opr_form_kegiatan_laporan_adms
          this.isLoading = false
          this.form = opr_form_kegiatan_laporan_adms
          this.itemPeralatans = opr_keg_mb_lp_peralatans
        })
      }
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} Days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} Hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} Minutes`

      /* produktivitas */
      const decDays = Number(days) * 24
      const decMinutes = Number(minutes) / 60
      const decimalValue = Number(decDays) + Number(hours) + Number(decMinutes)
      // const decimalDuration = ` | Decimal Value : ${decimalValue.toFixed(2)}`

      const produktivitas = Number(this.form.volume) / Number(decimalValue)
      this.form.wkt_produktivitas = produktivitas.toFixed(2)

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted
        // decimalDuration
      ].join('')
    },
    onStartChange(selectedDates, dateStr, instance, model) {
      console.log('dateStr', dateStr)
      this.configs.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.dpconfig.maxDate = dateStr
    },
    // jenisProduk(val) {
    //   this.jenis_produk = val
    //   this.playSound()
    // },
    // metodePelayanan(val) {
    //   this.metode_pelayanan = val
    //   this.playSound()
    // },
    playSound() {
      // Create an Audio object with an open-source sound file URL
      const audio = new Audio('mouse-click.mp3')
      // Play the audio
      audio.play()
    },
    async formSubmitted() {
      try {
        this.isLoading = true
        const payload = {
          ...this.form
        }
        if (!!this.dataID) {
          await useJwt.http
            .put(`opr_form_kegiatan_laporan_adm/${this.dataID}`, {
              ...payload,
              itemPeralatans: this.itemPeralatans
            })
            .then(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Updated',
                  icon: 'EditIcon',
                  variant: 'success'
                }
              })
              this.$router.go(-1)
            })
            .catch((error) => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'XCircleIcon',
                  variant: 'danger'
                }
              })
            })
        } else {
          await useJwt.http
            .post('opr_form_kegiatan_laporan_adm', {
              ...payload,
              itemPeralatans: this.itemPeralatans
            })
            .then(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'SaveIcon',
                  variant: 'success'
                }
              })
              this.$router.go(-1)
            })
            .catch((error) => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'XCircleIcon',
                  variant: 'danger'
                }
              })
            })
        }
      } catch (error) {
        console.log('an error on sent notification :: ', error)
      }
    },
    validationPhase1() {
      return new Promise((resolve, reject) => {
        this.$refs.phase1Rule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationPhase2() {
      return new Promise((resolve, reject) => {
        this.$refs.phase2Rule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationPhase3() {
      return new Promise((resolve, reject) => {
        this.$refs.phase3Rule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>

<style lang="scss" scoped>
.yogi-ptsal {
  .yo-card {
    &:hover {
      transition: all 0.1s;
      scale: 1.1;
      box-shadow: 0 4px 8px rgb(0, 119, 255);
      opacity: 0.9;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
